import { capitalize, map, get, min, identity } from 'lodash';
import React from 'react';
import { Button } from '@commonsku/styles';

import { oauth } from '../utils';
import { window } from '../global';

const toQuery = (params) => {
  return map(params, (v, k) => {
    return `${k}=${v}`;
  }).join(',');
};

const setupOAuth2 = (provider, onSuccess) => {
  const pathname = `oauth2-provider/${provider}`;
  return oauth('GET', pathname, {})
    .then(({ json }) => {
      if (json.error) {
        throw new Error(json.error);
      } else if (json.authorization_url) {
        const { height, width } = window.screen;
        const w = min([width, 800]);
        const h = min([height, 800]);
        const top = (height - h) / 2;
        const left = (width - w) / 2;
        const child = window.open(
          json.authorization_url, 'OAuth2 Popup',
          toQuery({ width: w, height: h, top, left })
        );
        const interval = setInterval(() => {
          if (!child || child.closed) {
            clearInterval(interval);
          } else {
            try {
              if (child.location.pathname === `/v1/${pathname}`) {
                clearInterval(interval);
                child.close();
                onSuccess();
              }
            } catch (e) {
              // cross-origin error is expected, only need log other error
              if (e.name !== 'SecurityError') {
                throw e;
              }
            }
          }
        }, 500);
      } else {
          onSuccess();
      }
    })
    .catch((e) => {
      if (e instanceof Error) {
        throw e;
      } else {
        throw new Error(get(e, 'json.error', JSON.stringify(e)));
      }
    })
  ;
};

const OAuth2Button = ({ children, provider, onSuccess=identity, onError=identity, ...props }) => {
  return <Button
    size="small"
    onClick={() => {
      setupOAuth2(provider, onSuccess).catch((e) => {
        console.log(e);
        onError(e);
      });
    }}
    {...props}
  >{children || `Connect to ${capitalize(provider)}`}</Button>;
};

export default OAuth2Button;
